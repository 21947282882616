import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import multiguard from "vue-router-multiguard";
Vue.use(VueRouter);

// Logic for login gard for routes
const loggedIn = function (to, from, next) {
  if (!store.state.auth.data) {
    next({
      name: "PageAuthSignin",
    });
  }
  next();
};

// to redirect if already a student loged in
const loggedInSuccess = function (to, from, next) {
  if (store.state.auth.data) {
    next({ name: "PageDashboardHome" });
  }
  next();
};

// all route logics
const routes = [
  // Auth Pages
  {
    path: "/auth",
    redirect: "/auth/signin",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    beforeEnter: multiguard([loggedInSuccess]),
    children: [
      {
        path: "signin",
        name: "PageAuthSignin",
        component: () =>
          import(
            /* webpackChunkName: "auth-signin" */ "@/views/auth/Signin.vue"
          ),
        meta: {
          title: "Signin",
        },
      },
    ],
  },

  // Auth Pages
  {
    path: "",
    component: () =>
      import(
        /* webpackChunkName: "layout-dashboard" */ "@/layouts/LayoutDashboard.vue"
      ),
    beforeEnter: multiguard([loggedIn]),
    children: [
      {
        path: "/",
        name: "PageDashboardHome",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-home" */ "@/views/dashboard/Home.vue"
          ),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/users",
        name: "PageDashboardUsers",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-users" */ "@/views/dashboard/Users.vue"
          ),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/fishes",
        name: "PageDashboardFishes",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-fishes" */ "@/views/dashboard/Fishes.vue"
          ),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/auctions",
        name: "PageDashboardAuctions",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-auctions" */ "@/views/dashboard/Auctions.vue"
          ),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/auctions/:id",
        name: "PageDashboardBids",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-bids" */ "@/views/dashboard/Bids.vue"
          ),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/auctions/:id/views",
        name: "PageDashboardViews",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-bids" */ "@/views/dashboard/Views.vue"
          ),
        meta: {
          title: "Dashboard",
        },
      },
    ],
  },

  // Error Pages
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    children: [
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () =>
          import(
            /* webpackChunkName: "main-expaired" */ "@/views/error/PageErrorSessionExpired.vue"
          ),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () =>
          import(
            /* webpackChunkName: "main-not-found" */ "@/views/error/PageErrorNotFound.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

//page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title + " | F3" : "F3 Admin";
  });
});

export default router;
